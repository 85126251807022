/* windicss layer base */
*, ::before, ::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #f5f5f5;
}
* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(64, 169, 255, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
::moz-focus-inner {
  border-style: none;
  padding: 0;
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
a {
  color: inherit;
  text-decoration: inherit;
}
body {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  text-transform: none;
  background-color: transparent;
  background-image: none;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
button, [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: button;
}
button, [role="button"] {
  cursor: pointer;
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
h3, h4, h5 {
  font-size: inherit;
  font-weight: inherit;
}
p, h3, h4, h5 {
  margin: 0;
}
/* windicss layer components */
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
/* windicss layer utilities */
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 191, 191, var(--tw-bg-opacity));
}
.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 144, 255, var(--tw-bg-opacity));
}
.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(140, 140, 140, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 247, 255, var(--tw-bg-opacity));
}
.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 217, 217, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.bg-gradient-to-tr {
  background-image: -o-linear-gradient(bottom left, var(--tw-gradient-stops));
  background-image: -webkit-gradient(linear, left bottom, right top, from(var(--tw-gradient-stops)));
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}
.from-red-500 {
  --tw-gradient-from: rgba(255, 77, 79, var(--tw-from-opacity, 1));
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.from-black {
  --tw-gradient-from: rgba(0, 0, 0, var(--tw-from-opacity, 1));
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.to-orange-600 {
  --tw-gradient-to: rgba(234, 88, 12, var(--tw-to-opacity, 1));
}
.to-gray-600 {
  --tw-gradient-to: rgba(140, 140, 140, var(--tw-to-opacity, 1));
}
.bg-center {
  background-position: center;
}
.bg-cover {
  background-size: cover;
}
.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(230, 247, 255, var(--tw-border-opacity));
}
.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(245, 245, 245, var(--tw-border-opacity));
}
.border-t-gray-200 {
  --tw-border-opacity: 1;
  border-top-color: rgba(245, 245, 245, var(--tw-border-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
.border-none {
  border-style: none;
}
.border-b {
  border-bottom-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.cursor-pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.hidden {
  display: none;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-col-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.place-self-end {
  -ms-grid-row-align: end;
  -ms-grid-column-align: end;
  place-self: end;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.flex-none {
  -webkit-box-flex: 0;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.flex-shrink {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}
.font-bold {
  font-weight: 700;
}
.h-7 {
  height: 1.75rem;
}
.h-20 {
  height: 5rem;
}
.h-64 {
  height: 16rem;
}
.h-14 {
  height: 3.5rem;
}
.text-sm {
  font-size: 0.9411rem;
}
.text-lg {
  font-size: 1.0588rem;
}
.text-xs {
  font-size: 0.8125rem;
}
.text-2xl {
  font-size: 1.4117rem;
}
.text-2xs {
  font-size: 0.7058rem;
}
.text-base {
  font-size: 1rem;
}
.text-xl {
  font-size: 1.1764rem;
}
.text-xsm {
  font-size: 0.8823rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.m-4 {
  margin: 1rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-24 {
  margin-bottom: 6rem;
}
.-mb-1 {
  margin-bottom: -0.25rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.max-h-66vh {
  max-height: 66vh;
}
.min-h-screen {
  min-height: 100vh;
}
.min-h-70vh {
  min-height: 70vh;
}
.min-w-22 {
  min-width: 5.5rem;
}
.opacity-100 {
  opacity: 1;
}
.outline {
  outline-style: solid;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.p-2 {
  padding: 0.5rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-2\.5 {
  padding: 0.625rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-0\.5 {
  padding: 0.125rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-24 {
  padding-bottom: 6rem;
}
.pt-4 {
  padding-top: 1rem;
}
.tab {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.bottom-0 {
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.right-4 {
  right: 1rem;
}
.top-4 {
  top: 1rem;
}
.bottom-0\.2 {
  bottom: 0.05rem;
}
.-bottom-12 {
  bottom: -3rem;
}
.right-0 {
  right: 0px;
}
.top-0 {
  top: 0px;
}
.bottom-48 {
  bottom: 12rem;
}
.right-2 {
  right: 0.5rem;
}
.top-2 {
  top: 0.5rem;
}
.top-1 {
  top: 0.25rem;
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -4px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0/0.1),0 1px 2px -1px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px -1px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(24, 144, 255, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(191, 191, 191, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(140, 140, 140, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 77, 79, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(245, 34, 45, var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.text-ellipsis {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.line-through {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}
.visible {
  visibility: visible;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.w-full {
  width: 100%;
}
.w-7 {
  width: 1.75rem;
}
.w-20 {
  width: 5rem;
}
.w-14 {
  width: 3.5rem;
}
.z-1001 {
  z-index: 1001;
}
.z-30 {
  z-index: 30;
}
.z-20 {
  z-index: 20;
}
.z-10 {
  z-index: 10;
}
.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.animated {
  -webkit-animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-fill-mode: both;
}