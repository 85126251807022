#root {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
:root:root {
  --font-size: 17px;
  --adm-color-text: #8c8c8c;
  --adm-color-primary: #1890ff;
  --adm-color-white: #fff;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #ffffff;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-border-color: #eeeeee;
  --adm-font-size-main: 13px;
  --adm-hd: 1;
  --adm-font-family: 'pingfang SC', 'helvetica neue', arial, 'hiragino sans gb',
        'microsoft yahei ui', 'microsoft yahei', simsun, sans-serif;
}
.App .adm-input, .App .adm-text-area-element {
  --font-size: 15px !important;
}
.adm-stepper {
  --input-width: 30px !important;
}
.adm-stepper .adm-stepper-input {
  background-color: transparent;
  font-weight: 700;
}
.adm-stepper .adm-button {
  border-radius: 9999px;
  width: 24px;
  height: 24px;
}
.adm-stepper .adm-stepper-plus {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 144, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.adm-stepper .adm-stepper-minus {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 191, 191, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.adm-selector-check-mark-wrapper {
  display: none;
}
.adm-selector-item {
  --checked-border: 1px solid var(--checked-text-color);
  --checked-color: #e6f7ff;
  --text-color: #262626;
  --border: 1px solid transparent;
  border-radius: 0.375rem;
  font-size: 0.8823rem;
  min-width: 6rem;
  text-align: center;
}
.App .adm-form-item-label {
  font-size: 0.8823rem !important;
  --tw-text-opacity: 1 !important;
  color: rgba(38, 38, 38, var(--tw-text-opacity)) !important;
}
.App .adm-form {
  ---border-inner: 0 !important;
  ---border-top: 0 !important;
  ---border-bottom: 0 !important;
}
.App .adm-list-body {
  --font-size: 15px;
}
.App .adm-button {
  font-size: 1rem;
}
.App .adm-radio {
  --icon-size: 22px;
  --font-size: 17px;
  --gap: 8px;
}
.App .adm-button.adm-button-small {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
}
.adm-radio-block .adm-radio-content {
  width: 100%;
}
.cell-group > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.cell-group {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  font-size: 0.9411rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.adm-modal-body .adm-modal-title {
  font-size: 24px !important;
  margin-bottom: 24px !important;
}
.adm-modal-body .adm-modal-content {
  font-size: 17px !important;
}
.adm-modal-wrap {
  min-width: 24rem;
}
@media (max-width: 575.98px) {
  .adm-modal-wrap {
    min-width: 90vw !important;
    max-width: 95vw !important;
  }
}
@media screen and (min-width: 992px) {
  .home {
    padding-bottom: 5rem;
  }
  body {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
  }
  main.home .aside.adm-side-bar {
    --width: 200px !important;
    --width: auto;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    font-size: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .adm-tabs-header {
    border-style: none !important;
  }
  .adm-tabs-tab {
    padding: 15px 0 15px !important;
  }
  .adm-tabs {
    border-radius: 0.375rem;
  }
  #root .home {
    max-width: 1024px;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  #root .others {
    max-width: 768px;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .bottom-bar-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    width: 100%;
  }
  .adm-popup-body-position-bottom {
    left: 50% !important;
  }
  .adm-modal-wrap {
    min-width: 27rem !important;
  }
  .adm-popup-body {
    border-radius: 0.375rem;
    max-width: 32rem;
    min-width: 25vw;
    top: 50%;
    left: 50%;
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-translate-z: 0 !important;
    --tw-rotate: 0 !important;
    --tw-rotate-x: 0 !important;
    --tw-rotate-y: 0 !important;
    --tw-rotate-z: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    --tw-scale-z: 1 !important;
    -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) !important;
    -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) !important;
    --tw-translate-x: -50% !important;
    --tw-translate-y: -50% !important;
  }
  .top-slider {
    border-radius: 0.375rem;
    margin-bottom: 2rem !important;
    overflow: hidden;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -4px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    --tw-shadow-color: rgba(245, 245, 245, 1);
    --tw-shadow: var(--tw-shadow-colored);
  }
  .top-slider .me {
    display: none;
  }
  .tooltip .me, .tooltip .bag {
    display: block;
  }
  .top-slider .store-meta {
    max-width: 32rem;
  }
  main.home > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  main.home {
    background-color: transparent;
  }
  main.home .aside {
    border-radius: 0.375rem;
    overflow: hidden;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -4px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    --tw-shadow-color: rgba(245, 245, 245, 1);
    --tw-shadow: var(--tw-shadow-colored);
  }
  main.home .aside.adm-side-bar .adm-side-bar-items > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  main.home .aside.adm-side-bar .adm-side-bar-item {
    background-color: transparent;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  main.home .aside.adm-side-bar .adm-side-bar-item-highlight {
    display: none;
  }
  main.home .aside.adm-side-bar .adm-side-bar-item-title {
    text-align: center;
  }
  main.home .aside .adm-side-bar-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .product-list {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: -1rem;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: -1rem;
    padding-left: 0px;
    padding-right: 0px;
  }
  .product-list article>div .cover {
    height: 135px;
    width: 100%;
  }
  .product-list > article {
    margin-top: 1rem !important;
    margin-right: 0px !important;
    margin-bottom: 1rem !important;
    margin-left: 1rem !important;
    width: 9.5rem !important;
  }
  .product-list article > div > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .product-list article > div {
    border-radius: 0.5rem !important;
    border-width: 1px !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    -webkit-flex-direction: column !important;
    flex-direction: column !important;
    overflow: hidden !important;
    padding: 0.5rem !important;
  }
  .product-list article > div .detail {
    min-height: 5rem;
  }
  .header-bar {
    margin-bottom: 2rem;
    position: relative !important;
  }
  .header-bar.adm-nav-bar {
    background-color: transparent;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  }
  .header-bar .adm-nav-bar-title {
    font-size: 1.5rem;
  }
  section {
    border-radius: 0.375rem;
    overflow: hidden;
    padding: 2.5rem !important;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -4px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    --tw-shadow-color: rgba(245, 245, 245, 1);
    --tw-shadow: var(--tw-shadow-colored);
  }
  .groupbuy-button {
    margin: 1rem;
  }
  .cell {
    padding: 2.5rem !important;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1) !important;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -4px var(--tw-shadow-color) !important;
    -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow) !important;
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow) !important;
    --tw-shadow-color: rgba(245, 245, 245, 1) !important;
    --tw-shadow: var(--tw-shadow-colored) !important;
  }
  footer {
    border-radius: 0.375rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-top: 2rem;
    overflow: hidden;
    padding: 2.5rem !important;
    position: relative !important;
    position: fixed !important;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -4px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    --tw-shadow-color: rgba(245, 245, 245, 1);
    --tw-shadow: var(--tw-shadow-colored);
    z-index: 20 !important;
  }
  .popup-visible .adm-mask {
    opacity: 1 !important;
  }
  .popup-invisible {
    display: none !important;
  }
  .overflow-y-scroll {
    overflow-y: auto !important;
  }
  .adm-picker-header-button {
    cursor: pointer !important;
  }
}
.adm-image-viewer-slides-inner > * {
  margin-right: 0 !important;
}
.gmy-home-search {
  display: flex;
}
.gmy-home-input {
  margin: 0.3em;
  border-radius: 0.5em;
}
.gmy-home-but {
  width: 35% !important;
  margin: 0.3em;
}
.gmy-home-index-img {
  width: 55px;
  height: 30px;
  margin-left: 8px;
  margin-top: 11px;
}